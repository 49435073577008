<template>
  <div class="view flex flex-d ai-c" style="position: relative;">
    <headers :styleSize="'min'" :times="data.time"></headers>
    <img src="@/assets/speaial_activity_img/image/time_title.png" class="time-title" alt="">
    <div class="title-view flex ai-c">
      <img src="@/assets/speaial_activity_img/image/icon_title.png" class="icon" alt="">
      <img src="@/assets/speaial_activity_img/image/fivetitleen_11.png" class="title" alt="">
      <!-- <img src="@/assets/speaial_activity_img/image/fivetitleen.png" class="title" alt=""> -->
      <!-- <span class="time-hint">（当前数据统计截止时间11月10日，11日数据将于24:00后更新）</span> -->
    </div>
    <div class="number">{{ streamSalesFormatted }}</div>
    <div class="main flex  flex-1  jc-sb">
      <div class="left">
        <SubTitleAndIcon>
          <p>社区团购平台GMV排名</p>
        </SubTitleAndIcon>
        <div class="ranking-view">
          <Rankind :list="rankingList"></Rankind>
        </div>
      </div>
      <div class="right">
        <SubTitleAndIcon>
          <p>11.1-11.11社区团购平台每日GMV</p>
        </SubTitleAndIcon>
        <div class="chart-view">
          <LineChart :data="echartData"></LineChart>
        </div>
      </div>
    </div>
    <Annotation hint="免责声明见第11页。"></Annotation>
  </div>
</template>

<script>
import Headers from '@/components/Big/Header.vue'
import Rankind from '@/components/Big/Rankind.vue'
import LineChart from '@/components/Big/LineChart'
import TitleAndTime from '@/components/Big/TitleAndTime.vue'
import SubTitleAndIcon from '@/components/Big/SubTitleAndIcon'
import Annotation from '@/components/Big/Annotation'

import toolMixin from '@/utils/tool'
export default {
  mixins: [toolMixin],
  components: {
    Headers,
    Rankind,
    LineChart,
    TitleAndTime,
    SubTitleAndIcon,
    Annotation,
  },
  props: {
    data: {
      type: [Object],
      default: () => ({}),
    },
  },
  data() {
    return {
      times: '',
      rankingList: [],
      echartData: {},
    }
  },
  watch: {
    data: {
      handler(newValue, oldValue) {
        this.init()
      },
      deep: true,
    },
  },
  computed: {
    streamSalesFormatted() {
      const formattedValue = (this.data.communitySales / 100000000).toFixed(2)
      const unit = '亿元'
      return formattedValue + unit
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if (Object.keys(this.data).length == 0) return
      let newValue = this.data
      this.rankingList = []
      let communityRankPlatform = [...newValue.communityRankPlatform]
      //设置左侧的图
      if (communityRankPlatform && communityRankPlatform.length != 0) {
        this.rankingList = communityRankPlatform
          .slice(0, 3)
          .map((res, index) => {
            return {
              name: res.platform,
            }
          })
      }
      //设置右侧的图
      if (
        newValue.communityRankDate &&
        newValue.communityRankDate.length != 0
      ) {
        let dataX = [],
          dataY = []
        newValue.communityRankDate.forEach((item, index) => {
          dataX.push(this.timeDisposal(item.date))
          dataY.push((item.sales / 100000000).toFixed(2))
        })
        this.echartData = {
          dataX,
          dataY,
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/rem.scss';
@import './style/zn.scss';
</style>
